@import "src/mixins";
@import "src/zindex";

.nav-dashboard-overlay {
  content: '';
  position: absolute;
  z-index: map-get($zIndex, navigationDashboard);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.nav-dashboard {
  position: absolute;
  z-index: map-get($zIndex, navigationDashboard);
  top: 0;
  bottom: 0;
  background-color: var(--nav-dashboard-bg);

  span.small {
    @include color(var(--nav-dashboard-caption-color));
  }

  header {
    height: 100px;
    background-color: var(--nav-dashboard-header-bg);

    h1, span.small {
      @include color(var(--nav-dashboard-header-color));
    }

    .icon {
      width: 55px;
      height: 42px;
      line-height: 4.2rem;
    }

    .border-left {
      border-left: 1px solid #FFF;
    }
  }

  footer {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 18px;
    color: var(--nav-dashboard-footer-color);
    background-color: var(--nav-dashboard-footer-bg);
  }

  .user-block {
    border-top: 2px solid var(--nav-dashboard-user-border-color);
  }

  &-modules {
    @extend .nav-dashboard;

    left: 6px;
    width: 300px;
    z-index: 1200;

  }

  &-services {
    @extend .nav-dashboard;

    left: 306px;
    width: 300px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    header {
      .icon {
        width: auto;
      }
    }

    .theme-default {
      header {
        background-color: var(--theme-tsms-header-bg)
      }

      --primary-brand: var(--villagile-blue1);
      --brand-dark: var( --odivea-blue-dark);
      --primary-contrast: var(--white);
    }

    .btn-primary-brand:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
    }


    /* LEGACY to remove */

    .theme-tsms {
      header {
        background-color: var(--theme-tsms-header-bg)
      }
    }

    .theme-kadran {
      header {
        background-color: var(--theme-tsms-header-bg)
      }
    }

    .theme-odivea {
      header {
        background-color: var(--theme-odivea-header-bg);
      }

      --brand-dark: var(--theme-odivea-button-bg);
      --primary-brand: var(--theme-odivea-button-bg);
      --primary-contrast: var(--theme-odivea-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }

    .theme-seramm {
      header {
        background-color: var(--theme-seramm-header-bg);
      }

      --brand-dark: var(--theme-seramm-button-bg);
      --primary-brand: var(--theme-seramm-button-bg);
      --primary-contrast: var(--theme-seramm-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }

    .theme-sig-seramm {
      header {
        background-color: var(--theme-sig-seramm-header-bg);
      }

      --brand-dark: var(--theme-sig-seramm-button-bg);
      --primary-brand: var(--theme-sig-seramm-button-bg);
      --primary-contrast: var(--theme-sig-seramm-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }

    .theme-baignade-water-quality {
      header {
        background-color: var(--theme-baignade-water-quality-header-bg);
      }

      --brand-dark: var(--theme-baignade-water-quality-button-bg);
      --primary-brand: var(--theme-baignade-water-quality-button-bg);
      --primary-contrast: var(--theme-baignade-water-quality-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }

    .theme-synoptique-udb {
      header {
        background-color: var(--theme-synoptique-udb-header-bg);
      }

      --brand-dark: var(--theme-synoptique-udb-button-bg);
      --primary-brand: var(--theme-synoptique-udb-button-bg);
      --primary-contrast: var(--theme-synoptique-udb-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }

    .theme-synoptique-ude {
      header {
        background-color: var(--theme-synoptique-ude-header-bg);
      }

      --brand-dark: var(--theme-synoptique-ude-button-bg);
      --primary-brand: var(--theme-synoptique-ude-button-bg);
      --primary-contrast: var(--theme-synoptique-ude-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }

    .theme-seramm-site-publication {
      header {
        background-color: var(--theme-seramm-site-publication-header-bg);
      }

      --brand-dark: var(--theme-seramm-site-publication-button-bg);
      --primary-brand: var(--theme-seramm-site-publication-button-bg);
      --primary-contrast: var(--theme-seramm-site-publication-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }

    .theme-seramm-documentation-works {
      header {
        background-color: var(--theme-seramm-documentation-works-header-bg);
      }

      --brand-dark: var(--theme-seramm-documentation-works-button-bg);
      --primary-brand: var(--theme-seramm-documentation-works-button-bg);
      --primary-contrast: var(--theme-seramm-documentation-works-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }

    .theme-seramm-mail-management {
      header {
        background-color: var(--theme-seramm-mail-management-works-header-bg);
      }

      --brand-dark: var(--theme-seramm-mail-management-works-button-bg);
      --primary-brand: var(--theme-seramm-mail-management-works-button-bg);
      --primary-contrast: var(--theme-seramm-mail-management-works-button-color);

      .btn-primary-brand:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 61, .5);
      }
    }
  }
}

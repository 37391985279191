// keep sorted from lower to higher
$zIndex: (
        base: 1,
        navigationDashboard: 1050,
        aboveMap: 1100,
        header : 5000,
        loading: 10000
);

// base level elements here
.z-base {
  z-index: map-get($zIndex, base);
}

// navigation level elements here
.dashboard {
  .nav {
    z-index: map-get($zIndex, navModules);
  }
}

// aboveMap level elements here
.DateRangePicker_picker {
  z-index: map-get($zIndex, aboveMap);
}

.intervention-details-container {
  z-index: map-get($zIndex, aboveMap);
}

.map-spinner {
  z-index: map-get($zIndex, aboveMap);
}

// header level elements here

// loading level elements here
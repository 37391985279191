@import "mixins";

select {
  &.form-control {
    height: 48px;
  }
}

.custom-switch {
  &.switch-only {
    padding: 0;
  }

  .custom-control-input:checked {
    ~ .custom-control-label {
      &::before {
        border-color: var(--switch-checked-button);
        background-color: var(--switch-checked-button);
      }
      &::after {
        transform: translateX(2.4rem);
      }
    }
  }

  .custom-control-label {
    &::before {
      left: 0;
      width: 4.8rem;
      height: 1.56rem;
      border-radius: 1.5rem;
      background-color: var(--switch-bg);
      border: var(--switch-bg) solid 1px;
    }

    &::after {
      top: 2px;
      left: 2px;
      width: 2rem;
      height: 2rem;
      border-radius: 1.5rem;
      background-color: var(--switch-button);
    }
  }
}

.elementForm {
  &.yearMonthDatePicker .selectYearMonth {
    min-width: 15em
  }

  &.yearDatePicker .selectYear {
      min-width: 8em
  }
}
@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';
@import '~react-leaflet-fullscreen-plugin/Control.FullScreen.css';

.leaflet-container {
  width: auto;
  height: 100%;
  min-height: 500px;
}

.marker-global-cluster-custom {
  background: var(--marker-cluster-bg);
  border: 2px solid var(--marker-cluster-border);
  border-radius: 50%;
  color: var(--marker-cluster-color);
  line-height: 26px;
  text-align: center;

  &__planned {
    @extend .marker-global-cluster-custom;
    background: var(--status-planned);
  }

  &__ongoing {
    @extend .marker-global-cluster-custom;
    background: var(--status-ongoing);
  }
}

.intervention-details-container {
  left: 21px;

  article {
    background: var(--tile-bg);
    overflow-y: auto;

    header {
      background-color: var(--box-header-light);
    }

    > div {
      background: var(--tile-bg);

      &.itv-type {
        background: var(--type-tile-bg);
      }
    }
  }
}

.map-container {
  .leaflet-control-geosearch {
    form {
      max-width: 350px;

      input {
        height: 48px;
      }
    }

    .results {
      > .active,
      > :hover {
        background-color: var(--map-search-input-bg);
        border-color: var(--map-search-input-active-bg);
        cursor: pointer;
      }
    }

    a {
      &.leaflet-bar-part {
        &:before {
          font-size: 1.56rem;
          font-family: 'sz-icon-bold' !important;
          content: '\f16f';
          transform: none;
          border: none;
          top: inherit;
          left: inherit;
          width: 48px;
        }

        &:after {
          border: none;
        }
      }

      &.reset {
        line-height: 48px;

        &:hover {
          background-color: var(--map-search-input-active-bg);
          border: none;
        }
      }
    }
  }

  .leaflet-right .leaflet-control-geosearch form {
    right: 48px;
  }

  .leaflet-control-layers {
    border: 1px solid var(--white);
    border-radius: 2px;
    background: var(--map-control-layers-bg);
    color: var(--map-control-text);

    .leaflet-control-layers-toggle {
      width: 48px;
      height: 48px;
      padding: 12px;
      background-image: none;
      font-family: 'sz-icon-bold';
      font-size: 1.56rem;
      line-height: 1;
      color: var(--map-control-icon-color);

      &:before {
        content: '\f164';
      }
    }
  }

  .leaflet-right {
    .leaflet-control {
      margin-right: 0;
    }
  }

  .leaflet-bar {
    box-shadow: none;

    a {
      width: 48px;
      height: 48px;
      line-height: 48px;
      color: var(--map-control-icon-color);
      background-color: var(--map-control-bg);

      &:first-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }

      &:last-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    button {
      background-color: rgba(3, 15, 64, 0.3);
      border: none;
      border-radius: 2px;
      width: 48px;
      height: 48px;
      padding: 12px;

      svg {
        fill: var(--map-control-icon-color);
      }
    }

    .button-icon {
      font-size: 1.125rem;
    }

    .control-icon {
      font-size: 1.56rem;
      line-height: 48px;
    }
  }

  .leaflet-touch {
    .leaflet-bar {
      @extend .leaflet-bar;
    }
  }
}

@import '~@suezenv/react-theme-components/assets/scss/1-settings/bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import "~bootstrap/scss/utilities";

// v5 is future
.top-0 {
  top: 0;
}

.end-0 {
  right: 0;
}

.request .container-fluid {
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;;
  margin-right: auto;
  margin-left: auto;
}

.overflow-visible {
  overflow: visible;
}

.rounded {
  border-radius: 10px !important;
}

.rounded-2 {
  border-radius: 2px;
}

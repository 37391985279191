@import "src/zindex";

.sz-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: map-get($zIndex, header) !important;

  &.unlogged {
    padding-right: 20rem;
  }

  .logo {
    height: 40px;
  }

  .title {
    justify-content: center;
    flex-grow: 2;
  }

  .group {
    display: flex;
    height: 60px;
    padding: 0 18px;
    text-align: center;
    align-items: center;

    + .group {
      border-left: 2px solid var(--toulouse-grey1);
    }

    &.no-border {
      border-left: 0;
    }
  }

  .login {
    .dropdown-button-toggle {
      .sz-icon-bold {
        display: inline-block;
        width: 36px;
        height: 36px;
        margin-left: 16px !important;
        background: url('/assets/icon/login.svg') no-repeat;
        vertical-align: middle;

        &:before {
          content: none;
        }
      }
    }

    .dropdown-menu {
      left: auto !important;
      right: 0;
      min-width: 250px;

      li {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}

.sz-dropdown .sz-list_item a {
  padding: 0;
}

.sz-list_item {
  padding: 0;

  a {
    padding: 0 1rem;
    height: 60px;
    line-height: 60px;
  }
}

.dropzone {
  width: 75px;
  height: 75px;
  display: inline-flex;
  border: 2px solid var(--drop-zone-border);
  cursor: pointer;

  &-box {
    display: flex;
    width: 100%;
    height: 100%;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
  }

  &--preview-img {
    max-width: 65px;
    max-height: 65px;
  }
}

.remove-file {
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 25px;
  cursor: pointer;
  transform: rotateZ(45deg);

  i::before{
    font-size: 1rem;
  }
}

.modal-tab-container {
  background-color: var(--tab-container-bg-color);
  margin: 0 85px;

  .modal-tab-content {

    .indicators-form {

      &_category {
        border-bottom: 1px solid var(--category_row_border_color);
      }

      &_btn-container {
        .indicators-form_btn-save {
          width: 110px;
          background-color: var(--button_save_bg_border_color);
          border-color: var(--button_save_bg_border_color);
          text-align: right;

          &:hover, &:focus, &:active {
            background-color: var(--button_save_bg_border_hover_color);
            border-color: var(--button_save_bg_border_hover_color);

            &::after {
              background-color: var(--button_save_bg_border_hover_color);
            }
          }
        }
      }


    }
  }
}

.sz-dual-list-box {

    .option-box, .selected-box {
      &.scrollable {
        overflow-y: scroll;
        max-height: 350px;
      }

      height: 100%;
    }

  .pb-icon-size {
    padding-bottom: 56px;
  }
}

.tab-header{
  &--actions{

    &--search-container{
      width: 60%;
      &:focus-within {
        width: 100%;
      }
    }
  }
}
@import "~leaflet-geosearch/assets/css/leaflet.css";

.map-area {
  &.leaflet-container {
    min-height: 300px;
  }

  .area-polygon {
    fill: var(--map-area-polygon);
    stroke: var(--map-area-polygon);
    stroke-width: 4px;
    fill-opacity: 0.4;
  }
}

@import "~@suezenv/react-theme-components/assets/css/main.css";
@import "bootstrap";
@import "colors";
@import "forms";
@import "images";
@import "mixins";
@import "sz";
@import "text";
@import "themes";
@import "variables";
@import "zindex";

body {
  background: var(--body-background);
}

html, body, #root {
  height: 100%;
}

#root {
  padding-top: 60px;
  height: 100%;
}

main {
  background-color: var(--main-bg);
}

.full-screen-layout {
  margin-top: -60px;
}

a {
  color: var(--text-color);

  &:hover {
    color: var(--text-color-hover);
    text-decoration: none;
  }
}

.color {
  &-regular {
    @include color(var(--text-color-regular));
  }
}

.legend {
  list-style: none;

  &-inline {
    @extend .legend;
    padding: 0;

    li {
      display: inline-block;
      padding-right: 2rem;
    }
  }
}

.icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 1.4rem;
  overflow: inherit;

  &-big {
    @extend .icon;
    width: 24px;
    height: 24px;
    font-size: 1.56rem;
    line-height: 1.56rem;
  }

  &-tiny {
    @extend .icon;
    width: 12px;
    height: 12px;
    font-size: 0.75rem;
    line-height: 1.2rem;
  }
}

.intervention-icon {
  @each $key, $value in $status-list {
    &__#{$value} {
      fill: var(--status-#{$value});
    }
    &__#{$value}_selected {
      fill: var(--status-#{$value});
      stroke: var(--status-#{$value});
    }
  }
}

.bg-dark {
  @include background-color(var(--background-dark));
}

.no-scroll {
  overflow-y: hidden;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.tile {
  background-color: var(--extranet-white);
  box-shadow: var(--extranet-shadow);

  p {
    margin-bottom: 0;
  }

  .md-margin {
    margin: 8px 15px 20px 18px;
  }

  &.xl-padding {
    padding: 15px 16px 51px 24px;
  }
}

.box {
  padding: 24px;
  background-color: var(--box-bg);
  box-shadow: var(--zeus-shadow);

  &.box-no-padding {
    padding: 0;
  }

  &.box-no-shadow {
    box-shadow: none;
  }

  &.box-border {
    border-top: 4px solid var(--box-border-default);
  }

  &.box-dark {
    background-color: var(--box-background-dark);
    max-height: 100%;
  }
}

.anchor-right {
  position: relative
}

.sticker {
  width: 12px;
  height: 12px;
  display: inline-block;

  &.status-planned {
    background-color: var(--status-planned)
  }

  &.status-ongoing {
    background-color: var(--status-ongoing)
  }

  &.status-done {
    background-color: var(--status-done)
  }
}

.flex-flow-column {
  flex-flow: column;
}

.widget-container {
  min-height: 232px;
}

.title-decoration {
  &:before {
    content: '';
    display: inline-block;
    width: 54px;
    height: 6px;
    margin-right: 6px;
    background-color: var(--title-decoration);
    border-radius: 3px;
  }
}

.admin-tabs {
  .RRT__tabs {
    margin-bottom: 0;
  }
}

.multiline-translation {
  white-space: pre-line;
}

.ranking {
  background-color: var(--ranking-bg);
}

.react-date-picker__inputGroup {
  font-weight: unset !important;
  .react-date-picker__inputGroup__leadingZero {
     color: var(--secondary);
     font-family: "DINPro-Regular";
     font-size: 1em;
  }
}

.sz-select-user-role:nth-child(2)  {
  z-index: 3;
}

.typo-inactive-label {
  font-family: 'DINPro-Medium';
  font-weight: 'DINPro-Bold';
  color: var(--grey-inactive)
}

.mailto, .telto {
  color: var(--table-text-color) !important;
}

.icon-success{
  color: green;
}
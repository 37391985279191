@import "src/mixins";

.main-wrapper {
  height: 100%;
  overflow-y: hidden;

  main {
    height: 100%;
    overflow-y: hidden;
  }
}

.sz-aside {
  &__item {
    padding-left: 18px;

    &:before {
      background: var(--nav-item-link-active);
    }

    &.active {
      @include background(var(--nav-item-bg-active));
      @include color(var(--nav-item-text-active));
    }
  }

  .sz-aside_bottom-links {
    transition: 0.3s;
    opacity: 1;

    align-items: center;

    .btn {
      font-size: 0.750rem;
    }
  }

  .sz-aside_bottom-logo-container {
    border-top: 4px solid var(--navigation-bottom-logo-top-border);
    height: 34px;
    background-color: var(--navigation-bottom-logo-bg);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 21px;
    }
  }

  > nav {
    width: 100%;
  }
}

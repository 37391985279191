@mixin background($color) {
  background: $color!important;
}

@mixin background-color($color) {
  background-color: $color!important;
}

@mixin border($size, $color) {
  border: $size solid $color!important;
}

@mixin border-color($color) {
  border-color: $color!important;
}

@mixin color($color) {
  color: $color!important;
}

@import "mixins";

.legend {
  span.d-block {
    display: inline-block !important;
  }
}

.sz-button-group {
  &__btn-choose {
    height: 36px;
    border: 1px solid var(--extranet-grey6) !important;

    &.active {
      @include background-color(var(--background-dark));
    }
  }

  > {
    .sz-button-group__btn-choose:not(:first-child) {
      margin-left: 0 !important;
    }
  }
}

.sz-left-border {
  &.cat-documents::after {
    background-color: var(--module-documents);
  }

  &.cat-indicators::after {
    background-color: var(--module-indicators);
  }

  &.cat-portal::after {
    background-color: var(--module-portal);
  }

  &.cat-requests::after {
    background-color: var(--module-requests);
  }
}

.btn-arrowed {
  height: 48px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--btn-arrowed-bg);
  box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 1px 3px 0 rgba(17, 95, 251, 0.1);
  justify-content: left;
  border: none;
  overflow: hidden;
  text-align: left;

  i {
    position: relative;
    left: -18px;
    color: var(--btn-icon-color);
    font-size: 1.56rem;
  }

  &::before {
    font-family: 'sz-icon-line';
    content: '\f117';
    right: 18px;
    position: absolute;
    font-size: 1rem;
  }
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0 !important;
}

.bg-none {
  background: none;
}

.checkbox-big {
  min-height: 1.56rem;
  padding: 1rem 2rem;

  label {
    &::before, &::after {
      width: 1.56rem;
      height: 1.56rem;
    }
  }
}

.RRT__container {
  .RRT__tabs {
    font-size: 0.875rem;

    .RRT__tab {
      padding: 13px 15px;

      &.RRT__tab--selected {
        background-color: transparent;
        border-bottom-color: var(--tab-border-bottom);
      }
    }
  }
}

.sz-table {
  color: var(--table-text-color);

  .sz-table-header {
    background-color: transparent;
  }

  thead {
    font-size: 0.75rem;
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: var(--table-tbody-tr-odd);
      }
    }
  }
}

.step {
  &.sz-body-stepper {
    &.stepper-grey {
      background-color: #f4f8ff !important;

      .request-box {
        padding: 3px;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }

    &.stepper-white {
      background-color: white !important;
    }
  }
}

.sz-aside {
  &__list {
    .sz-aside {
      &__item {
        &:first-child {
          margin-top: auto !important;
        }
      }
    }
  }
}

@import "src/zindex";

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: map-get($zIndex, loading);
  height: 2em;
  width: 2em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

/* Spinner */
.loading-spinner {
  div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--loading-spinner-color);
    animation: loading-spinner 1.2s linear infinite;

    &:nth-child(1) {
      top: 6px;
      left: 6px;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      top: 6px;
      left: 26px;
      animation-delay: -0.4s;
    }

    &:nth-child(3) {
      top: 6px;
      left: 45px;
      animation-delay: -0.8s;
    }

    &:nth-child(4) {
      top: 26px;
      left: 6px;
      animation-delay: -0.4s;
    }

    &:nth-child(5) {
      top: 26px;
      left: 26px;
      animation-delay: -0.8s;
    }

    &:nth-child(6) {
      top: 26px;
      left: 45px;
      animation-delay: -1.2s;
    }

    &:nth-child(7) {
      top: 45px;
      left: 6px;
      animation-delay: -0.8s;
    }

    &:nth-child(8) {
      top: 45px;
      left: 26px;
      animation-delay: -1.2s;
    }

    &:nth-child(9) {
      top: 45px;
      left: 45px;
      animation-delay: -1.6s;
    }
  }
}

@keyframes loading-spinner {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.tag-textarea {
  min-width: 100%;
  border: none;

  &:hover, &:focus, &:active {
    border: none;
  }
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;

  &__selected{
    display: inline;

    &-tag{
      display: inline-block;
      box-sizing: border-box;
      margin: 0 6px 6px 0;
      padding: 6px 8px;
      border: 1px solid #D1D1D1;
      border-radius: 2px;
      background: #F1F1F1;

      /* match the font styles */
      font-size: inherit;
      line-height: inherit;

      &:after{
        content: '\2715';
        color: #AAA;
        margin-left: 8px;
      }

      &:hover, &:focus{
        border-color: #B1B1B1;
      }
    }
  }

  &__search{

    @media screen and (min-width: 30em) {
      position: relative;
    }

    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;

    &-input{
      /* prevent autoresize overflowing the container */
      max-width: 100%;

      /* remove styles and layout from this element */
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;

      /* match the font styles */
      font-size: inherit;
      line-height: inherit;
    }

    &-input {
      min-width: 190px;
    }
  }
}

:root {
  --zeus-blue1: #F4F8FF;
  --zeus-blue2: #3D466C;
  --zeus-blue3: #2E88FF;
  --zeus-blue4: #00A4B6;
  --zeus-blue5: #030F40;
  --zeus-blue6: #D6F1FB;
  --zeus-blue7: #59B9FF;
  --zeus-blue8: #C9E6FB;
  --zeus-blue9: #0F1827;
  --zeus-blue10: #77B7FC;
  --zeus-blue11: #92CAD5;
  --zeus-orange1: #FF8363;
  --zeus-purple1: #C65BAB;
  --zeus-purple2: #c01955;
  --zeus-red0: #e58f9e;
  --zeus-red1: #EB4A65;
  --zeus-red2: #800E21;
  --zeus-yellow1: #DEB861;
  --zeus-grey1: #F8F9FA;
  --zeus-grey2: #E9ECEF;
  --zeus-grey3: #6C757D;
  --zeus-grey4: #D7D8DF;
  --zeus-grey5: #D5D6DE;
  --zeus-grey6: #ABAFBF;
  --zeus-grey7: #82C3CF;
  --zeus-green1: #C0F1CC;
  --zeus-white: #FFFFFF;
  --zeus-hydrometric: #D4EFF9FF;

  --zeus-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 2px 4px 0 rgba(17, 95, 251, 0.1);

  --signal-blue-1: #60BFC0;
  --request-blue-1: #60BFC0;
  --document-blue-5: var(--zeus-blue5);
  --odivea-blue-dark: #004A83;
  --villagile-blue1: #006EB6;
  --seramm-blue-1: #528BDE;
  --grey-inactive: #767c97;
  --grey-disabled-light: #F4F8FF;

  --main-bg: var(--zeus-blue1);
  --text-color: var(--zeus-blue5);
  --text-color-hover: var(--zeus-blue3);
  --text-color-regular: var(--zeus-blue2);
  --background-dark: var(--zeus-grey5);
  --title-decoration: var(--zeus-blue5);
  --tab-border-bottom: var(--zeus-blue5);
  --loading-spinner-color:var(--zeus-blue5);

  /* Button arrowed */
  --btn-arrowed-bg: var(--zeus-white);
  --btn-icon-color: var(--zeus-grey5);

  /* Navigation */
  --nav-item-link: var(--zeus-blue2);
  --nav-item-link-active: var(--zeus-blue5);
  --nav-dashboard-bg: var(--zeus-grey1);
  --nav-dashboard-header-bg: var(--zeus-blue9);
  --nav-dashboard-header-color: var(--white);
  --nav-dashboard-user-border-color: var(--zeus-grey2);
  --nav-dashboard-caption-color: var(--zeus-grey3);
  --nav-dashboard-footer-bg: var(--zeus-grey2);
  --nav-dashboard-footer-color: var(--zeus-grey3);
  --nav-separator-color: var(--zeus-grey7);
  --nav-active-dot-color: var(--zeus-grey7);

  --navigation-bottom-logo-bg: var(--zeus-blue9);
  --navigation-bottom-logo-top-border: var(--zeus-blue10);

  /* 
   * Navigation themes
   *
   * ALL THEMES ARE DEPRECATED !
   */

  --theme-odivea-header-bg: var(--odivea-blue-dark);
  --theme-odivea-button-bg: var(--odivea-blue-dark);
  --theme-odivea-button-color: var(--white);
  --theme-tsms-header-bg: var(--white);

  --theme-seramm-header-bg: var(--white);
  --theme-seramm-button-bg: var(--seramm-blue-1);
  --theme-seramm-button-color: var(--white);

  --theme-sig-seramm-header-bg: var(--white);
  --theme-sig-seramm-button-bg: var(--seramm-blue-1);
  --theme-sig-seramm-button-color: var(--white);

  --theme-baignade-water-quality-header-bg: var(--white);
  --theme-baignade-water-quality-button-bg: var(--seramm-blue-1);
  --theme-baignade-water-quality-button-color: var(--white);

  --theme-synoptique-udb-header-bg: var(--white);
  --theme-synoptique-udb-button-bg: var(--seramm-blue-1);
  --theme-synoptique-udb-button-color: var(--white);

  --theme-synoptique-ude-header-bg: var(--white);
  --theme-synoptique-ude-button-bg: var(--seramm-blue-1);
  --theme-synoptique-ude-button-color: var(--white);

  --theme-seramm-site-publication-header-bg: var(--white);
  --theme-seramm-site-publication-button-bg: var(--seramm-blue-1);
  --theme-seramm-site-publication-button-color: var(--white);

  --theme-seramm-documentation-works-header-bg: var(--white);
  --theme-seramm-documentation-works-button-bg: var(--seramm-blue-1);
  --theme-seramm-documentation-works-button-color: var(--white);

  --theme-seramm-mail-management-works-header-bg: var(--white);
  --theme-seramm-mail-management-works-button-bg: var(--seramm-blue-1);
  --theme-seramm-mail-management-works-button-color: var(--white);

  /* DropZone*/
  --drop-zone-border: var(--zeus-blue1);
  --drop-zone-title-font: var(--zeus-grey-3);

  /* Box */
  --box-bg: var(--zeus-white);
  --box-border-default: var(--zeus-blue5);
  --box-border-light: var(--zeus-grey4);
  --box-header-light: var(--zeus-blue6);
  --box-shadow: var(--zeus-shadow);
  --box-title: var(--zeus-blue2);
  --box-background-dark: var(--zeus-grey1);

  /* Marker clusters */
  --marker-cluster-bg: var(--status-done);
  --marker-cluster-border: var(--white);
  --marker-cluster-color: var(--white);

  /* Status */
  --status-planned: #DEB861;
  --status-ongoing: #2E88FF;
  --status-done: #30D158;

  /* Text & icons colors */
  --text-color-light: var(--zeus-grey3);
  --icon-primary-color: var(--zeus-blue5);

  /* Switches */
  --switch-bg: var(--zeus-grey5);
  --switch-button: var(--white);
  --switch-checked-button: var(--zeus-blue5);

  /* Notifications */
  --notifications-header-color: var(--zeus-blue2);
  --notifications-header-caption: var(--zeus-grey3);
  --notifications-collapse-caption: var(--zeus-grey3);

  /* intervention details */
  --status-tile-active: var(--zeus-grey2);
  --status-tile-border: var(--zeus-grey6);
  --status-tile-border-active: var(--zeus-blue2);
  --type-tile-bg: var(--zeus-grey1);
  --tile-bg: var(--zeus-grey1);
  --text-tile: var(--zeus-grey3);
  --subtext-tile: var(--zeus-blue5);

  /* drink water */
  --dark-blue: var(--zeus-blue2);
  --sky-blue: var(--zeus-blue7);

  /* widget chart */
  --widget-zone0-color: var(--zeus-green1);
  --widget-zone1-color: var(--zeus-orange1);
  --widget-zone2-color: var(--zeus-red1);
  --widget-plot-line-color: var(--zeus-grey5);
  --widget-title-color: var(--zeus-blue2);
  --widget-subtitle-color: var(--zeus-grey3);

  /* cartographie */
  --map-legend-title-text-color: var(--toulouse-grey4);
  --map-control-icon-color: var(--zeus-blue2);
  --map-control-text: var(--zeus-blue2);
  --map-control-bg: rgba(248, 249, 250, 0.7);
  --map-control-layers-bg: rgba(248, 249, 250, 0.7);
  --map-search-input-active-bg: var(--zeus-grey5);
  --map-search-input-bg: var(--main-bg);
  --map-area-polygon: var(--zeus-blue11);

  /* Meteo Blue */
  --weather-bg: var(--zeus-blue8);
  --weather-subtext: var(--zeus-grey3);

  /* User Preference Modal */
  --tab-container-bg-color: var(--zeus-grey1);
  --category_row_border_color: var(--zeus-grey5);
  --button_save_bg_border_color: var(--zeus-blue10);
  --button_save_bg_border_hover_color: var(--zeus-blue3);

  /* Table */
  --table-header-text-color: var(--zeus-grey3);
  --table-tbody-tr-odd: var(--zeus-grey1);
  --table-text-color: var(--zeus-grey3);

  /* Contract */
  --contract-detail-box-background: var(--zeus-grey1);

  /* Signal */
  --widget-header: var(--zeus-blue3);
  --widget-header-signal: var(--signal-blue-1);
  --widget-header-request: var(--request-blue-1);

  --ranking-bg: var(--zeus-red1);

  /* Comment */
  --comment: var(--zeus-blue9);

  /* Hydrometric */
  --widget-header: var(--zeus-blue3);
  --widget-header-signal: var(--signal-blue-1);
}

.color-primary {
  color: var(--text-color);
}

.color-gray-600 {
  color: var(--zeus-grey3);
}

.color-icon-primary {
  fill: var(--text-color);
}

.color-danger {
  color: var(--zeus-red1);
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-50 {
  opacity: 0.50;
}

.opacity-25 {
  opacity: 0.25;
}

.bg-disabled-light {
  background-color: var(--grey-disabled-light) !important;
}

.bg-light-blue {
  background-color: #bfdcff;
}

.bg-dark-blue {
  background-color: #060f40;
}

.color-light {
  color: #ccc
}

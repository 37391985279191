
.attachment {
    img, .img-size { /* Class .img-size is used to get div button with same size of image */
        width: 200px;
        height: 181px;
    }

    .delete-picto {
        margin-top: -3em;
        position: absolute;
    }
}

.carousel-preview {
    div.img-container {
        width: 200px;
        height: 180px; 
        display: flex;
        justify-content: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        width: auto;
        height: auto;
    }
}

.sz-carousel {
    .sz-carousel__img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
@import '~bootstrap/scss/mixins';

.sz-side-menu {
  background-color: var(--main-bg);

  // allow scrolling but hide scrollbar
  overflow-y: scroll;
  scrollbar-width: none;

  // FIXME: utiliser l'une des deux solutions suivantes qui sont commenté
  @media (max-width: 575.98px) {
    width: 100%;

    > nav {
      width: 100%;
    }
  }
  //@include media-breakpoint-down(sm) {
  //  width: 100%;
  //
  //  > nav {
  //    width: 100%;
  //  }
  //}
  //@media ($container-max-widths(sm)) {
  //  width: 100%;
  //
  //  > nav {
  //    width: 100%;
  //  }
  //}

  @media (min-width: 576px) {
    width: 213px;
    &:not(.open), &.sz-aside-exit-done {
      width: 80px;

      .sz-aside__item--hidden-close {
        display: none !important;
        transition: .3s ease;
        transition-delay: .3s;
        max-width: calc(100% - 48px);
      }
    }
  }

  transition: 0.3s ease;


  ul {
    list-style: none;

    li {
      transition: 0.5s ease;
      padding: 0.5rem 1.5rem 0.65rem 1.5rem;
      position: relative;

      -webkit-border-top-right-radius: 0.375rem;
      -webkit-border-bottom-right-radius: 0.375rem;
      -moz-border-radius-topright: 0.375rem;
      -moz-border-radius-bottomright: 0.375rem;
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;

      &:after {
        opacity: 0;
        width: 4px;
        border-radius: 2px;
        background-color: var(--nav-active-dot-color);
        content: "";
        position: absolute;
        right: 3px;
        top: 4px;
        bottom: 4px;
        transition: 0.3s ease;
      }

      &.active:not(.external_link) {
        background-color: var(--white);
        box-shadow: 2px 2px 4px 0 rgba(17, 95, 251, 0.05);

        &:after {
          opacity: 1;
        }
      }

      .sz-aside__item--hidden-close {
        max-width: calc(100% - 36px);
      }
    }

    li.sz-side-menu--toggle-open-btn {
      padding-left: 2rem;
      border-radius: 0;
    }

    &::after {
      background-color: var(--nav-separator-color);
      height: 2px;
      width: 90%;
      display: inline-block;
      content: "";
      margin: auto;
      position: relative;
      left: 5%;
    }

    &:last-child::after, &:first-child::after {
      display: none;
      content: none;
      height: 0;
      width: 0;
    }
  }

  &--toggle-open-btn {
    background-color: var(--white);
  }
}
